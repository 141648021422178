<template>
  <v-menu :close-on-content-click="false" offset-y max-width="250" class="">
    <template v-slot:activator="{ on, attrs }">
      <v-btn :x-small="mini" icon v-bind="attrs" v-on="on">
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>

    <v-list min-width="250px">
      <v-list-item
        link
        @click="
          copyToClipboard(fiche.fiche.ticket, $nSuccess($t('actions.copied')))
        "
      >
        <v-list-item-title class="warning--text">{{
          $t('messagerie.chat.copyTicket')
        }}</v-list-item-title>
      </v-list-item>

      <v-menu left :offset-x="true" :offset-y="false">
        <template v-slot:activator="{ on, attrs }">
          <v-list-item v-bind="attrs" v-on.stop="on">
            <v-list-item-content>
              <v-list-item-title class="teal--text">
                {{ $t('messagerie.chat.change_state') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-list>
          <v-list-item v-for="etat in etats" :key="etat.id">
            <v-list-item-title>
              <v-chip
                @click="changeDataFiche('etat', etat)"
                :disabled="fiche.fiche.etat.value.id == etat.value.id"
                :color="getColorEtat(etat.value.id)"
                label
                dark
                class="font-weight-bold elevation-1"
              >
                {{ $t('messagerie.parametrages.' + etat.text) }}
              </v-chip>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu left :offset-x="true" :offset-y="false">
        <template v-slot:activator="{ on, attrs }">
          <v-list-item class="" v-bind="attrs" v-on="on" color="teal">
            <v-list-item-content>
              <v-list-item-title class="teal--text ">
                {{ $t('messagerie.chat.change_impact') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-list>
          <v-list-item v-for="impact in impacts" :key="impact.id">
            <v-list-item-title>
              <v-chip
                @click="changeDataFiche('impact', impact)"
                :disabled="fiche.fiche.impact.value.id == impact.value.id"
                :color="getImpactColorSingle(impact.value.id)"
                label
                dark
                class="font-weight-bold elevation-1"
                >{{ $t('messagerie.parametrages.' + impact.text) }}
              </v-chip>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu left :offset-x="true" :offset-y="false">
        <template v-slot:activator="{ on, attrs }">
          <v-list-item class="" v-bind="attrs" v-on="on">
            <v-list-item-content>
              <v-list-item-title class="teal--text ">
                {{ $t('messagerie.chat.change_property') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-list>
          <v-list-item v-for="ordre in ordre_priorites" :key="ordre.id">
            <v-list-item-title>
              <v-chip
                @click="changeDataFiche('priorite', ordre)"
                :disabled="
                  fiche.fiche.ordre_priorite.value.id == ordre.value.id
                "
                :color="getPrioriteColorSingle(ordre.value.id)"
                label
                dark
                class="font-weight-bold elevation-1"
                >{{ $t('messagerie.parametrages.' + ordre.text) }}
              </v-chip>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu left :offset-x="true" :offset-y="false">
        <template v-slot:activator="{ on, attrs }">
          <v-list-item class="" v-bind="attrs" v-on="on">
            <v-list-item-content>
              <v-list-item-title class=" teal--text">
                {{ $t('messagerie.chat.change_type_demande') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-list>
          <v-list-item v-for="type in type_demandes" :key="type.id">
            <v-list-item-title>
              <v-chip
                @click="changeDataFiche('type_demande', type)"
                :disabled="fiche.fiche.type_demande.value.id == type.value.id"
                :color="getTypeColorSingle(type.value.id)"
                label
                dark
                class="font-weight-bold elevation-1"
                >{{ $t('messagerie.parametrages.' + type.text) }}
              </v-chip>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu left :offset-x="true" :offset-y="false" v-if="user.superAdministrator">
        <template v-slot:activator="{ on, attrs }">
          <v-list-item class="" v-bind="attrs" v-on="on">
            <v-list-item-content>
              <v-list-item-title class=" teal--text">
                Date prévisionnelle
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-list>
          <v-list-item >
            <v-list-item-title>
              <v-card-text>
            <v-container>
              <v-row>
                <v-col >
                    <span>Selectionner une date</span>
                  <v-menu>
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-on="on"
                        outlined
                        dense
                        hide-details
                        @input="handleDateSelection"
                        v-model="exceptedDate"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="exceptedDate"
                      :locale="$i18n.locale"
                    ></v-date-picker>
                  </v-menu>
                  <!-- bouton save -->
                  <v-btn
                    color="success"
                    @click="sendExceptedDate()"
                    :disabled="!exceptedDate"
                  >
                    {{ $t('actions.save') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- ------------ date previsionnelle ------------ -->

      <v-dialog v-model="dialogModule" max-width="1000px">
        <template v-slot:activator="{ on, attrs }">
          <v-list-item
            class=""
            v-bind="attrs"
            v-on="on"
            @click="openChangeModule"
          >
            <v-list-item-content>
              <v-list-item-title class=" teal--text">
                {{ $t('messagerie.chat.change_module') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-card>
          <v-card-title>
            <span class="text-h5">{{
              $t('messagerie.chat.change_module')
            }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-btn color="yellow darken-2" dark @click="clearChangeModule">{{
                $t('actions.clear')
              }}</v-btn>
              <v-col cols="4">
                <v-select
                  :items="modulesWithOther"
                  :label="$t('messagerie.text.type.module')"
                  v-model="changeModule.module"
                  item-value="id"
                  return-object
                >
                  <template slot="selection" slot-scope="data">
                    {{ data.item.name }}
                  </template>
                  <template slot="item" slot-scope="data">
                    {{ data.item.name }}
                  </template>
                </v-select>
              </v-col>
              <v-col cols="4">
                <v-select
                  :disabled="subModules.length == 0"
                  :label="$t('messagerie.text.type.sous_module')"
                  :items="subModules"
                  v-model="changeModule.sous_module"
                  item-value="id"
                  return-object
                >
                  <template slot="selection" slot-scope="data">
                    {{ data.item.name }}
                  </template>
                  <template slot="item" slot-scope="data">
                    {{ data.item.name }}
                  </template>
                </v-select>
              </v-col>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue" text @click="dialogModule = false">
              {{ $t('actions.close') }}
            </v-btn>
            <v-btn color="success" text @click="sendUpdatedModule()">
              {{ $t('actions.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-divider class="my-2" v-if="rightsUser.hotline"></v-divider>
      
      <!-- btn transmette la fiche pour suivi -->
      <v-list-item
        class=""
        v-if="rightsUser.hotline && !fiche.fiche.suivi"
        @click="transmettreSuiviFiche()"
      >
        <v-list-item-content>
          <v-list-item-title class="warning--text ">
            Transmettre pour suivi
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        class=""
        v-if="rightsUser.hotline && fiche.fiche.suivi"
        @click="removeSuiviFiche()"
      >
      <v-list-item-content>
          <v-list-item-title class="warning--text ">
            Retirer le suivi
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- appel client -->
      <v-list-item
        class=""
        v-if="rightsUser.hotline"
        @click="dialogAppelClient = true"
      >
        <v-list-item-content>
          <v-list-item-title class="warning--text ">
            <!-- {{ $t('messagerie.chat.call_client') }} -->
            Appel client
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item> 
      <v-dialog :key="modaleCallClientKey"
        v-model="dialogAppelClient"
        max-width="700"
        v-if="rightsUser.hotline"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">
              <!-- {{ $t('messagerie.chat.call_client') }} -->
              Appel client
            </span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="4">
                  <v-select
                    :items="[userConnect]"
                    label="Utilisateur"
                    v-model="callClient.user"
                    :item-text="item => item.lastname + ' ' + item.firstname"
                  ></v-select>
                </v-col>
                <v-col cols="4">
                  <v-select
                    :items="callClient.raisons"
                    label="Raison"
                    v-model="callClient.details"
                    :rules="[detailsRule]"
                  ></v-select> 
                </v-col>
                <v-col cols="4" class="align-self-center"> 
                  <v-menu>
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-on="on"
                        label="Date"
                        v-model="callClient.date"
                        :rules="[dateRule]"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="callClient.date"
                      :locale="$i18n.locale"
                      @change="updateDateTime"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12">
                  <!-- carousel -->
                  <v-carousel  v-if="callClient.history.length > 0"
                    hide-delimiters
                    hide-controls
                    height="200"
                    class="elevation-1"
                  >
                  <!-- changer taille icon arrow carousel -->
                  <template v-slot:prev="{ on }">
                    <v-btn
                      icon
                      v-on="on"
                      class="elevation-8"
                      style="width: 30px; height: 30px;"
                    >
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                  </template>
                    <v-carousel-item
                      v-for="(history, i) in reversedHistory"
                      :key="i"
                    > 
                      <v-card
                        class="d-flex flex-column justify-space-between"
                        height="200"
                      >
                        <v-sheet
                          color="blue lighten-5"
                          height="100%"
                          tile
                        >
                            <div 
                              class="fill-height d-flex flex-column justify-center align-center"
                            >
                              <v-card-title> 
                                <span class="black--text d-flex align-center">
                                  <!-- historique -->
                                  <v-icon class="mr-1" color="black" dark>mdi-history</v-icon>
                                  Historique
                                </span>
                              </v-card-title>
                              <div 
                                class="d-flex flex-column justify-space-between black--text"
                              >
                                <div>
                                  <v-icon color="black" dark size="20" class="mr-1">mdi-calendar</v-icon>{{ history.date | formatDate }}
                                </div>
                                <div>
                                  <v-icon color="black" dark size="20" class="mr-1">mdi-account</v-icon>{{ history.user }} 
                                </div>
                                <div>
                                  <v-icon color="black" dark size="20" class="mr-1">mdi-text</v-icon>{{ history.details }} 
                                </div>
                                <v-card-actions class="justify-center">
                                <v-btn v-if="userConnect.id == '49' || '34'"
                                  color="red"
                                  icon
                                  title="Supprimer de l'historique"
                                  class="elevation-2"
                                  @click="deleteHistory(history.appel_id)"
                                >
                                  <v-icon>mdi-close</v-icon>
                                </v-btn>
                              </v-card-actions>
                              </div>
                            </div>
                        </v-sheet>
                      </v-card>
                    </v-carousel-item>
                    <template v-slot:next="{ on }">
                      <v-btn
                        icon
                        v-on="on"
                        class="elevation-8"
                        style="width: 30px; height: 30px;"
                      >
                        <v-icon>mdi-chevron-right</v-icon>
                      </v-btn>
                    </template>
                  </v-carousel> 
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue" text @click="closeAppelClient">
              {{ $t('actions.close') }}
            </v-btn>
            <v-btn color="success" text @click="saveAppelClient()" :disabled="!isCallClientFormValid">
              {{ $t('actions.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-divider class="my-2" v-if="rightsUser.hotline"></v-divider>

      <v-dialog
        v-model="dialogDetails"
        max-width="1000px"
        v-if="asRightDetails"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-list-item class="" v-bind="attrs" v-on="on">
            <v-list-item-content>
              <v-list-item-title class="warning--text ">
                {{ $t('messagerie.chat.popup_details') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-card>
          <v-card-title>
            <span class="text-h5">{{
              $t('messagerie.chat.popup_details')
            }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-textarea
                :label="$t('messagerie.chat.popup_comment')"
                v-model="fiche.fiche.comment"
              ></v-textarea>
              <v-text-field
                :label="$t('messagerie.chat.popup_module')"
                v-model="fiche.fiche.label_module"
              ></v-text-field>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue" text @click="dialogDetails = false">
              {{ $t('actions.close') }}
            </v-btn>
            <v-btn color="success" text @click="sendDetailsFiche()">
              {{ $t('actions.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="dialogGestionUsers"
        max-width="1000px"
        v-if="rightsUser.hotline || getTagsCompteur"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-list-item
            class=""
            v-bind="attrs"
            v-on="on"
            @click="openDialogViewFiche"
          >
            <v-list-item-content>
              <v-list-item-title class="warning--text ">
                {{ $t('messagerie.chat.user_management') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-card>
          <v-card-title>
            <span class="text-h5">{{
              $t('messagerie.chat.user_management')
            }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col :cols="rightsUser.hotline ? 6 : 12" class="pr-5">
                  <v-row class="flex-column">
                    <h3 class="mb-5">
                      {{ $t('messagerie.chat.users_managements.add') }}
                    </h3>
                    <v-autocomplete
                      :label="$t('messagerie.chat.users_managements.users')"
                      multiple
                      :filter="autocompleteUser"
                      :items="addUsersFiche.selectUsers"
                      v-model="addUsersFiche.users"
                      chips
                      persistent-hint
                      item-value="id"
                    >
                      <template v-slot:message="{ message }">
                        <span v-html="message"></span>
                      </template>
                      <template slot="selection" slot-scope="data">
                        <v-chip
                          color="primary"
                          v-if="data.item.superAdministrator"
                          >{{ data.item.lastname + ' ' + data.item.firstname }}
                        </v-chip>
                        <v-chip v-else
                          >{{ data.item.lastname + ' ' + data.item.firstname }}
                        </v-chip>
                      </template>
                      <template slot="item" slot-scope="data">
                        {{ data.item.lastname + ' ' + data.item.firstname }}
                      </template>
                    </v-autocomplete>
                    <v-btn
                      color="success"
                      @click="addUsersFicheFunction()"
                      :disabled="addUsersFiche.users.length == 0"
                      >{{ $t('actions.add') }}
                    </v-btn>
                    <h3 class="mb-5">
                      {{ $t('messagerie.chat.users_managements.delete') }}
                      <v-tooltip bottom v-if="removeUsersFiche.length > 0">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="removeUsersFicheFunction()"
                            class="ml-3"
                            color="error"
                            fab
                            x-small
                            dark
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        <span>{{
                          $t('actions.delete') +
                            ' ' +
                            removeUsersFiche.length +
                            ' ' +
                            $t('messagerie.text.type.users')
                        }}</span>
                      </v-tooltip>
                    </h3>
                    <vue-scroll
                      class="pr-5 vue-scroll-list-remove-user"
                      :ops="optionsScrollChat"
                    >
                      <v-card
                        v-for="user in fiche.fiche.users"
                        :key="user.id"
                        class="dialog-gestion-users--div-suppression mb-3 pl-3"
                      >
                        <div>
                          <v-avatar size="64">
                            <img
                              style="width: 80px;height: auto;"
                              :src="
                                require('@/Assets/Images/Interface/Profiles/profile-man-1.svg')
                              "
                              alt="Profile photo"
                            />
                          </v-avatar>
                        </div>
                        <p>{{ usernameUser(user) }}</p>
                        <v-checkbox v-model="removeUsersFiche" :value="user">
                        </v-checkbox>
                      </v-card>
                    </vue-scroll>
                  </v-row>
                </v-col>
                <v-col cols="6" v-if="rightsUser.hotline">
                  <v-row class="flex-column">
                    <h3 class="mb-5">
                      {{ $t('messagerie.chat.tags_managements.add') }}
                    </h3>
                    <v-autocomplete
                      :label="$t('messagerie.chat.tags_managements.tags')"
                      multiple
                      :filter="autocompleteTags"
                      :items="addTagsFiche.selectTags"
                      v-model="addTagsFiche.tags"
                      chips
                      persistent-hint
                      item-value="_id"
                    >
                      <template v-slot:message="{ message }">
                        <span v-html="message"></span>
                      </template>
                      <template slot="selection" slot-scope="data">
                        <v-chip color="primary"
                          >{{ data.item.tag.name }}
                        </v-chip>
                      </template>
                      <template slot="item" slot-scope="data">
                        {{ data.item.tag.name }}
                      </template>
                    </v-autocomplete>
                    <v-btn
                      color="success"
                      @click="addTagsFicheFunction()"
                      :disabled="addTagsFiche.tags.length == 0"
                      >{{ $t('actions.add') }}
                    </v-btn>
                    <h3 class="mb-5">
                      {{ $t('messagerie.chat.tags_managements.delete') }}
                      <v-tooltip bottom v-if="removeTagsFiche.length > 0">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="removeTagsFicheFunction()"
                            class="ml-3"
                            color="error"
                            fab
                            x-small
                            dark
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        <span>{{
                          $t('actions.delete') +
                            ' ' +
                            removeTagsFiche.length +
                            ' ' +
                            $t('messagerie.text.type.users')
                        }}</span>
                      </v-tooltip>
                    </h3>
                    <vue-scroll
                      class="pr-5 vue-scroll-list-remove-user"
                      :ops="optionsScrollChat"
                      v-if="rightsUser.hotline"
                    >
                      <v-card
                        v-for="tag in getTagsFiche(fiche.fiche)"
                        :key="tag.id"
                        class="dialog-gestion-users--div-suppression mb-3 pl-3"
                      >
                        <v-checkbox v-model="removeTagsFiche" :value="tag">
                        </v-checkbox>
                        <p>{{ tag.tag.name }}</p>
                      </v-card>
                    </vue-scroll>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue" text @click="dialogGestionUsers = false">
              {{ $t('actions.close') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-divider class="my-2" v-if="rightsUser.hotline"></v-divider>

      <v-list-item v-if="rightsUser.hotline || getTagsCompteur" @click="setDoublon(false)">
        <v-list-item-content>
          <v-list-item-title>
            {{ $t('messagerie.fiche.doublon') }}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-icon v-if="fiche.fiche.doublon" right color="success"
            >mdi-check</v-icon
          >
        </v-list-item-action>

        <v-dialog v-model="dialogDoublon" max-width="480">
          <v-card>
            <v-card-title
              >{{ $t('messagerie.fiche.remove_doublon') }}
            </v-card-title>
            <v-card-text>
              <p>
                <v-icon color="warning" class="mr-3">mdi-alert</v-icon>
                {{ $t('messagerie.fiche.sure_remove_doublon') }}
              </p>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="dialogDoublon = false"
                >{{ $t('actions.cancel') }}
              </v-btn>
              <v-btn color="error" @click="setDoublon(true)"
                >{{ $t('actions.confirm') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-list-item>

      <v-list-item
        class=""
        v-if="rightsUser.hotline"
        @click="archivedFiche(!fiche.fiche.archived)"
      >
        <v-list-item-content>
          <v-list-item-title class="error--text ">
            <template v-if="fiche.fiche.archived">
              {{ $t('messagerie.chat.unarchive') }}
            </template>
            <template v-else>
              {{ $t('messagerie.chat.unarchive') }}
            </template>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import HotlineBus from '@/Components/Views/Hotline/HotlineBus';
import MessagerieService from '@/Services/MessagerieService';
import Vuex from 'vuex';
import moment from 'moment';

export default {
  name: 'MenuFiche',
  props: {
    mini: Boolean,
    fiche: Object,
    rightsUser: Object,
    impacts: Array,
    etats: Array,
    type_demandes: Array,
    ordre_priorites: Array,
    users: Array,
    tagsPlaneteOnline: Array,
    user: Object,
    openHover: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      exceptedDate: null,
      changeModule: {
        module: null,
        sous_module: null,
      },
      dialogDoublon: false,
      dialogModule: false,
      dialogGestionUsers: false,
      dialogDetails: false,
      dialogAppelClient: false,
      addUsersFiche: {
        selectUsers: [],
        users: [],
      },
      addTagsFiche: {
        selectTags: [],
        tags: [],
      },
      removeUsersFiche: [],
      removeTagsFiche: [],
      callClient: {
        user: '',
        details: '',
        history: [],
        raisons: ['Atelier', 'Appel'],
        date: null,
      },
      modaleCallClientKey: 0,
      dateRule: (v) => !!v || 'Champ requis',
      detailsRule: (v) => !!v || 'Champ requis',
      optionsScrollChat: {
        bar: {
          keepShow: true,
        },
        scrollPanel: {
          easing: 'easeInQuad',
          speed: 800,
        },
        vuescroll: {
          wheelScrollDuration: 300,
        },
        rail: {
          gutterOfEnds: '1px',
          gutterOfSide: '1px',
        },
      },
    };
  },
  // filter pour formater la date en francais
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YYYY HH:mm');
      }
    },
  },
  computed: {
    ...Vuex.mapState(['modules']),
    modulesWithOther() {
      let modules = this.modules.slice();
      modules.push({
        id: -1,
        name: this.$t('app.modules.other'),
        subModules: [],
      });
      return modules;
    },
    asRightDetails() {
      return (
        this.isMasterBase() &&
        (this.rightsUser.hotline || this.rightsUser.filter_detail)
      );
    },
    /**
     * retourne les sous module du module
     */
    subModules() {
      if (this.modulesWithOther) {
        return this.changeModule.module
          ? this.modulesWithOther.find(
              (mod) => mod.id == this.changeModule.module.id
            ).subModules
          : [];
      } else {
        return [];
      }
    },
    // retourne l'objet tag de tagplaneteonline
    getTagsCompteur() {
      return this.tagsPlaneteOnline.find((t) => t.tag.name == 'Compteur' &&
      t.users.find((u) => u == this.user.id)) && this.fiche.fiche.tags.find((t) => t.tag.name == 'Compteur') 
    },
    // retourne que l user qui est connecté
    userConnect() {
      return this.users.find((u) => u.id == this.user.id);
    },
    userFullName() {
      return this.user.lastname + ' ' + this.user.firstname;
    },
    reversedHistory() {
      return this.callClient.history.slice().reverse();
    },
    isCallClientFormValid() {
      return !!this.callClient.user && !!this.callClient.details && !!this.callClient.date;
    },
  },
  watch: {
    // alimente history de appel_client de la bdd
    dialogAppelClient(val) {
      if (val) {
        if (this.fiche && this.fiche.fiche && this.fiche.fiche.appel_client) {
          this.callClient.history = this.fiche.fiche.appel_client;
        }
        this.callClient.user = this.userFullName;
      }
    },
    // remplir le champ user de l'appel client automatiquement
  },
  methods: {
    /**
     * Méthode pour définir une fiche en doublon
     * @var valid On mets à true la variable pour afficher la modale de validation
     */
    async setDoublon(valid = false) {
      //Object pour envoi serveur
      let datas = {
        fiche: this.fiche.fiche.id,
        doublon: false,
      };
      let requete = false;
      //Valid pour la modal de confirmation
      if (valid) {
        this.fiche.fiche.doublon = false;
        this.dialogDoublon = false;
        requete = true;
        datas.doublon = false;
      } else {
        if (!this.fiche.fiche.doublon) {
          this.$set(this.fiche.fiche, 'doublon', true);
          requete = true;
          datas.doublon = true;
        } else {
          this.dialogDoublon = true;
        }
      }
      if (requete) {
        await MessagerieService.doublonFiche(datas).then(() => {
          if (datas.doublon) {
            this.$nSuccess(this.$t('messagerie.fiche.alert_doublon'));
          } else {
            this.$nSuccess(this.$t('messagerie.fiche.alert_unique'));
          }
        });
      }
    },
    getTagsFiche(fiche) {
      return fiche.tags.filter((t) => t.tag.name != 'Hotline');
    },
    //Permet de trier l'autocomplete des users par le nom - Gestion utilisateurs
    autocompleteUser(item, query, itemtext) {
      return (
        item.lastname.toLowerCase() + item.firstname.toLowerCase()
      ).includes(query.toLowerCase());
    },
    //Permet de trier l'autocomplete des tags par le nom - Gestion utilisateurs
    autocompleteTags(item, query, itemtext) {
      return item.tag.name.toLowerCase().includes(query.toLowerCase());
    },
    // suivsi de la fiche
    async transmettreSuiviFiche() {
      let datas = {
        fiche: this.fiche.fiche.id,
        isTracked: true,
      };
      const res = await MessagerieService.suiviFiche(datas);
      
      res ? this.$nSuccess("Fiche transmise pour suivi") : this.$nError("Erreur lors de la transmission de la fiche");
    },
    async removeSuiviFiche() {
      const res = await MessagerieService.removeSuiviFiche(this.fiche.fiche.id);
      
      res ? this.$nSuccess("Suivi retiré") : this.$nError("Erreur lors du retrait du suivi");
    },
    /**
     * Permet d'exporter fiche vers csv
     */
    exportFiche() {
      let CSV = '';

      let row =
        'Ticket; Demandeur; Urgence; Impact; Type de demande; Date demande; Module; Commentaire; Etape;';
      CSV += row + '\r\n';
      let fileName = 'Fiche_' + this.fiche.fiche.ticket;
      fileName = fileName.replace(/ /g, '_');
      //Initialize file format you want csv or xls
      CSV += this.fiche.fiche.ticket + ';';
      CSV +=
        this.fiche.fiche.userCreate.lastname +
        ' ' +
        this.fiche.fiche.userCreate.firstname +
        ';';
      CSV +=
        this.$t(
          'messagerie.parametrages.' + this.fiche.fiche.ordre_priorite.text
        ) + ';';
      CSV +=
        this.$t('messagerie.parametrages.' + this.fiche.fiche.impact.text) +
        ';';
      CSV +=
        this.$t(
          'messagerie.parametrages.' + this.fiche.fiche.type_demande.text
        ) + ';';

      let date = new Date(this.fiche.createdAt);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let dt = date.getDate();

      if (dt < 10) {
        dt = '0' + dt;
      }
      if (month < 10) {
        month = '0' + month;
      }
      CSV +=
        year +
        '-' +
        month +
        '-' +
        dt +
        ' ' +
        date.getHours() +
        ':' +
        date.getMinutes() +
        ';';
      CSV +=
        this.fiche.fiche.module.name +
        '/' +
        this.fiche.fiche.sous_module.name +
        ';';
      CSV += this.fiche.fiche.comment + ';';
      CSV +=
        this.$t('messagerie.parametrages.' + this.fiche.fiche.etat.text) + ';';
      let uri = 'data:text/csv;charset=utf-8,' + escape(CSV);

      // Now the little tricky part.
      // you can use either>> window.open(uri);
      // but this will not work in some browsers
      // or you will not get the correct file extension

      //this trick will generate a temp <a /> tag
      let link = document.createElement('a');
      link.href = uri;

      //set the visibility hidden so it will not effect on your web-layout
      link.style = 'visibility:hidden';
      link.download = fileName + '.csv';

      //this part will append the anchor tag and remove it after automatic click
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    openChangeModule() {
      if (this.fiche.fiche.module) {
        this.changeModule.module = this.fiche.fiche.module;
      }
      if (this.fiche.fiche.sous_module) {
        this.changeModule.sous_module = this.fiche.fiche.sous_module;
      }
      this.dialogModule = true;
    },
    /**
     * Set à null l'objet module
     */
    clearChangeModule() {
      this.changeModule.module = null;
      this.changeModule.sous_module = null;
    },
    /**
     * Permet de changer le module et sous module
     * @var archive boolean etat archive
     */
    async sendUpdatedModule() {
      await MessagerieService.changeModule({
        module: this.changeModule,
        fiche: this.fiche.fiche.id,
      }).then((res) => {
        this.fiche.fiche.module = this.changeModule.module;
        this.fiche.fiche.sous_module = this.changeModule.sous_module;
        this.$nSuccess(this.$t('messagerie.fiche.module_updated'));
        this.clearChangeModule();
        this.dialogModule = false;
      });
    },
    /**
     * Permet d'archiver une fiche
     * @var archive boolean etat archive
     */
    async archivedFiche(archive) {
      let datas = {
        fiche: this.fiche.fiche.id,
        archive,
      };
      await MessagerieService.archiveFiche(datas).then((res) => {
        this.fiche.fiche.archived = archive;
        HotlineBus.$emit('archived', this.fiche);
      });
    },
    /**
     * Fonction qui permet de changer les détails de la fiche
     */
    async sendDetailsFiche() {
      let datas = {
        fiche: this.fiche.fiche.id,
        comment: this.fiche.fiche.comment,
        label_module: this.fiche.fiche.label_module,
      };
      await MessagerieService.changeDetailsFiche(datas).then((res) => {
        this.$nSuccess(this.$t('messagerie.chat.popup_details_success'));
      });
    },
    // permet d'enrengistrer dans la fiche une date previsionnelle
    async handleDateSelection() {
      let datas = {
        fiche: this.fiche.fiche.id,
        exceptedDate: this.exceptedDate,
      };
      await MessagerieService.saveDatePrevisionnelle(datas).then((res) => {
        this.$nSuccess(this.$t('date previsionnelle enregistrée'));
      });
    },
    // permet d'envoyer la date previsionnelle
    async sendExceptedDate() {
      await this.handleDateSelection();
    },
    /**
     * Fonction qui permet de changer les données de la fiche
     * @var type le type de la données par exemple état
     */
    async changeDataFiche(type, value) {
      let user = this.user;
      delete user.photo;
      let datas = {
        fiche: this.fiche.fiche.id,
        type,
        object: null,
        user,
      };

      datas.object = value;
      await MessagerieService.changeParamsFiche(datas).then((res) => {
        if (type == 'etat') {
          this.fiche.fiche.etat = value;
          this.$nSuccess(this.$t('messagerie.chat.change_state_success'));
          if (
            value.text == 'etat.demande_terminee' ||
            value.text == 'etat.demande_cloturee'
          ) {
            this.$emit('setCurrentFicheId', this.fiche.fiche.id);

            this.$store.commit('setStatusFicheDone', {
              ficheId: this.fiche.fiche.id,
              status: true,
            });
          } else {
            this.$store.commit('setStatusFicheDone', {
              ficheId: this.fiche.fiche.id,
              status: false,
            });
          }
        }
        if (type == 'priorite') {
          this.fiche.fiche.ordre_priorite = value;
          this.$nSuccess(this.$t('messagerie.chat.change_property_success'));
        }
        if (type == 'impact') {
          this.fiche.fiche.impact = value;
          this.$nSuccess(this.$t('messagerie.chat.change_impact_success'));
        }
        if (type == 'type_demande') {
          this.fiche.fiche.type_demande = value;
          this.$nSuccess(
            this.$t('messagerie.chat.change_type_demande_success')
          );
        }
      });
    },

    /**
     * Concatène le nom de l'user
     * @var userId Id de l'user
     */
    usernameUser(user) {
      return user.firstname + ' ' + user.lastname.toUpperCase();
    },
    /**
     * Permet de définir qui voit la fiche entre les users et les tags
     */
    openDialogViewFiche() {
      let usersId;
      if (this.rightsUser.hotline) {
        usersId = this.fiche.fiche.users.map((u) => u.id);
      } else {
        usersId = this.fiche.fiche.users
          .filter((u) => u.quality != 'GTP')
          .map((u) => u.id);
      }
      this.addUsersFiche.selectUsers = this.users.filter(
        (u) => !usersId.includes(u.id && u.id != this.user.id)
      );
      if (this.rightsUser.hotline) {
        let tagsId = this.fiche.fiche.tags
          ? this.fiche.fiche.tags.map((t) => t._id)
          : [];
        this.addTagsFiche.selectTags = this.tagsPlaneteOnline.filter(
          (t) => !tagsId.includes(t._id)
        );
      }
    },
    /**
     * Permet d'ajouter des users à une fiche
     */
    async addUsersFicheFunction() {
      let users = this.users.filter((u) => {
        return this.addUsersFiche.users.includes(u.id);
      });
      await MessagerieService.addRemoveUsersFiche({
        fiche: this.fiche.fiche.id,
        users: users,
        type: 'add',
      }).then((res) => {
        this.fiche.fiche.users = this.fiche.fiche.users.concat(users);
        this.addUsersFiche.users = [];
      });
    },
    /**
     * Permet d'ajouter des tag à une fiche
     */
    async addTagsFicheFunction() {
      let tags = this.tagsPlaneteOnline.filter((t) =>
        this.addTagsFiche.tags.includes(t._id)
      );
      await MessagerieService.addRemoveTagsFiche({
        fiche: this.fiche.fiche.id,
        tags: tags,
        type: 'add',
      }).then((res) => {
        if (typeof this.fiche.fiche.tags == 'undefined') {
          this.fiche.fiche.tags = [];
        }
        this.fiche.fiche.tags = this.fiche.fiche.tags.concat(tags);
        this.addTagsFiche.tags = [];
      });
    },
    /**
     * Permet de retirer des users d'une fiche
     */
    async removeUsersFicheFunction() {
      if (
        confirm(
          'êtes vous sur de vouloir supprimer les ' +
            this.removeUsersFiche.length +
            ' utilisateurs de la fiche ?'
        )
      ) {
        await MessagerieService.addRemoveUsersFiche({
          fiche: this.fiche.fiche.id,
          users: this.removeUsersFiche.map((u) => u.id),
          type: 'delete',
        }).then((res) => {
          this.fiche.fiche.users = this.fiche.fiche.users.filter(
            (u) => !this.removeUsersFiche.includes(u)
          );
          this.removeUsersFiche = [];
        });
      }
    },
    /**
     * Permet de retirer des tags d'une fiche
     */
    async removeTagsFicheFunction() {
      if (
        confirm(
          'êtes vous sur de vouloir supprimer les ' +
            this.removeTagsFiche.length +
            ' tags de la fiche ?'
        )
      ) {
        await MessagerieService.addRemoveTagsFiche({
          fiche: this.fiche.fiche.id,
          tags: this.removeTagsFiche.map((u) => u._id),
          type: 'delete',
        }).then((res) => {
          this.fiche.fiche.tags = this.fiche.fiche.tags.filter(
            (u) => !this.removeTagsFiche.map((u) => u._id).includes(u._id)
          );
          this.removeTagsFiche = [];
        });
      }
    },
    // ajoute l'heure à la date
    updateDateTime(date) {
      this.callClient.date = date + ' ' + moment().format('HH:mm');
    },
    /**
     * Permet de sauvegarder un appel client
     */
    async saveAppelClient() {
      let datas = {
        user: this.callClient.user,
        user_id: this.userConnect.id,
        details: this.callClient.details,
        date: this.callClient.date,
        fiche: this.fiche.fiche.id,
      };
      await MessagerieService.saveAppelClient(datas).then((res) => {
        this.callClient.user = '';
        this.callClient.details = '';
        this.callClient.date = null;
      });
      this.dialogAppelClient = false;
      this.$nSuccess('Appel client enregistré');
    },
    /**
     * Permet de supprimer un appel client
     * @var id Id de l'appel client
     */
    async deleteHistory(id) {
       const res = await MessagerieService.deleteHistory(id)
      // mettre à jour l'historique
      if (res) {
        this.callClient.history = this.callClient.history.filter(
          (h) => h.appel_id != id
        );
      }
      this.$nSuccess('Appel client supprimé');
    },
    resetFormAppelClient() {
      this.callClient.user = '';
      this.callClient.details = '';
      this.callClient.date = null;
      this.detailsRule = (v) => !!v || 'Champ requis';
      this.dateRule = (v) => !!v || 'Champ requis';
      this.modaleCallClientKey += 1;
    },
    closeAppelClient() {
      this.dialogAppelClient = false;
      this.resetFormAppelClient();
    },
  },  
};
</script>

<style lang="scss">

</style>
